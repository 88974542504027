const environments = {
    local: {
        POTATO: 'expensite',
        PRICE: 1000000
    },
    development: {
        REACT_APP_LANDING : 'https://sampleforsetup.com',
        REACT_APP_DAILY_TOTAL_PASS_LIKE_REPORT : 50,
        REACT_APP_DAILY_TOTAL_LIKE : 20,
        REACT_APP_DAILY_TOTAL_REPORT : 5,
        REACT_APP_MATCH_QUOTA_VALUE_USD : 1,
        REACT_APP_MAX_USER_VERIFICATION_ATTEMPT_BY_MODERATOR : 3,
        REACT_APP_INACTIVE_HIDE : 7,
        REACT_APP_BLOCKED_DELETE : 31,
        REACT_APP_DELETED_DELETE : 31,
        REACT_APP_MESSAGES_PER_CHAT : 50,
        REACT_APP_CHARACTERS_PER_MESSAGE : 100,
        REACT_APP_MIN_RELOAD_USD : 10,
        REACT_APP_NINETY_DAYS_DELETE: 90
    },
    staging: {
        POTATO: 'expensite',
        PRICE: 1000
    },
    production: {
        POTATO: 'expensite',
        PRICE: 1000
    }
};

const getEnvVars = (env) => {
    switch (env) {
        case 'local':
            return environments.local;
        case 'dev':
            return environments.development;
        case 'stage':
            return environments.staging;
        case 'prod':
            return environments.production;
        default:
            return environments.local;
    }
};

module.exports = { getEnvVars };
import React from "react";
import Video from "../../elements/Video";
//import BrandFive from "../../components/brand/BrandFive";
import minglesea from "../../../src/assets/images/social_icons/minglesea.png";

const heroContent = {
  title: `The Magical Dating App`,
  description: `In here, your interests are 100% aligned with ours`,
  description1: `We are leveling the playing field for EVERYONE`,
  //buttonText: " Download - It’s free",
};

const HeroBannerEight = () => {
  return (
    <div className="hero-banner-eight">
      <div className="container">
        <div className="row">
          <div className="d-flex justify-content-center">
            <div style={{ marginLeft:'8px', marginBottom:'10px'}}>
              <img src={minglesea} alt="MingleSea" style={{ width: '120px', height: 'auto' }} />
            </div>
          </div>

          <div className="col-lg-10 m-auto">

            <h2 className="hero-heading" style={{ fontSize: '50px', lineHeight: '65px', color: '#004594' }}>
              {'MingleSea'}
            </h2>
            <h4 className="hero-heading" style={{ fontSize: '28px', lineHeight: '65px', color: '#0060ce' }}>
              {heroContent.title}
            </h4>
            <br />
            {/* <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
              style={{ fontSize: '25px', marginTop: '-20px' }}
            >
              {heroContent.description1}
            </p>
            <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
              style={{ fontSize: '25px', marginTop: '-50px' }}
            >
              {heroContent.description}
            </p> */}
            {/* <a
              href="#"
              className="theme-btn-nine"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {heroContent.buttonText}
            </a> */}
          </div>
        </div>
        
        <div className="video-wrapper" style={{
          marginTop: '50px', maxWidth: '300px', height: '587px',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px'
        }}>
          <Video />
        </div>


        {/* /.video-wrapper */}
      </div>

      <div className="hero-banner-ten" id="home">
        <div className="container">
          <div className="d-sm-flex align-items-center justify-content-center button-group">
            <a
              href="#"
              className="d-flex align-items-center ios-button"
            >
              <img src="images/icon/apple.svg" alt="icon" className="icon" />
              <div>
                <span>Download on the</span>
                <strong>App store</strong>
              </div>
            </a>
            <a
              href="#"
              className="d-flex align-items-center windows-button"
            >
              <img
                src="images/icon/playstore.svg"
                alt="icon"
                className="icon"
              />
              <div>
                <span>Get it on</span>
                <strong>Google play</strong>
              </div>
            </a>
          </div>
        </div>
      </div>



      <div className="partner-slider-two mt-130 md-mt-80">
        <div className="container">
          <div className="partnerSliderTwo">
            {/* <BrandFive /> */}
          </div>
        </div>
      </div>
      {/* /.partner-slider-two */}
      <img
        src="images/shape/173.svg"
        alt="shape"
        className="shapes shape-right"
      />
      <img
        src="images/shape/174.svg"
        alt="shape"
        className="shapes shape-left"
      />
    </div>
    //   /.hero-banner-eight
  );
};

export default HeroBannerEight;

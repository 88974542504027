//import React, { useState } from "react";
import { Helmet } from "react-helmet";
import DocEditorTabs from "../../components/block-slider/DocEditorTabs";
import FancyFeatureNinteen from "../../components/features/FancyFeatureNinteen";
import HeaderLandingEditor from "../../components/header/landing/HeaderLandingEditor";
import HeroBannerEight from "../../components/hero-banner/HeroBannerEight";
// import TestimonialSeven from "../../components/testimonial/TestimonialSeven";
//import FooterSeven from "../../components/footer/FooterSeven";
import CopyRightThree from "../../components/footer/CopyRightThree";
// import FacnyVideoTwo from "../../components/video/FacnyVideoTwo";
// import FacnyVideoThree from "../../components/video/FacnyVideoThree";
// import VideoGallery from "../../components/video/VideoGallery";
import FeatureFour from "../../components/features/FeatureFour";
import FancyFeatureTen from "components/features/FancyFeatureTen";
import FancyFeatureTenOne from "components/features/FancyFeatureTenOne";

const VideoEditorLanding = () => {
  // For header select menu
  //const [click1, setClick1] = useState(false);
  // const handleClick1 = () => setClick1(!click1);
  return (
    <div className="main-page-wrapper font-gordita">
      <Helmet>
        <title>
          MingleSea
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <div id="home" ></div>

      <HeaderLandingEditor />
      {/* End Header Landing Editor */}

      {/* 	=============================================
				Theme Hero Banner
			==============================================  */}
      <div style={{ marginBottom: '-50px', marginTop: '-20px' }}>
        <HeroBannerEight />
      </div>

      {/* 		=============================================
				Fancy Feature Eighteen
			==============================================  */}
      <div id="why" ></div>
      <div className="fancy-feature-eighteen mt-130 md-mt-80" style={{ marginRight: '10px', marginLeft: '10px' }} >
        <div className="container">
          <div className="row">
            <div className="col-xl-9 m-auto">
              <div
                className="title-style-eight text-center" style={{ marginTop: '-30px' }}
              >
                {/* <h2>
                  Here is why
                </h2>
                <br /> */}
                <h2>
                  Why are we different?
                </h2>
                {/* 
                <p>
                  Read from F to P to find out !!!
                </p> */}
              </div>
            </div>
          </div>

          {/* Nav tabs */}
        </div>
        {/* End .container */}
        <div>
          <DocEditorTabs />
        </div>


        <img src="images/shape/175.svg" alt="" className="shapes shape-right" />
        <img src="images/shape/176.svg" alt="" className="shapes shape-left" />
      </div>
      {/* /.fancy-feature-eighteen */}




      <div className="fancy-feature-ten pt-80 md-pt-50" id="ppm">
        <div className="bg-wrapper" style={{ backgroundColor: '#ebfff0', borderRadius: '0px' }}>
          <div className="container">
            <div className="row justify-content-between align-items-center" style={{marginBottom:'100px'}}>
              <div
                className="col-md-6"
              >
                <div className="title-style-six" style={{marginTop:'-20px'}}>
                  <h2>
                    <span style={{ color: '#0e4f00', textDecoration: 'none', fontFamily: 'sans-serif', fontWeight:'bold' }}>PPM</span>
                    <p style={{ marginTop: '30px', fontFamily: 'sans-serif', color: '#5a5a5a' }}>Pay Per Match</p>
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-5 col-md-6"
              >
                <div className="sub-text pt-50 ">
                  <p style={{ lineHeight: "25px", fontWeight:"500" }}>{`We are the first app to introduce PPM`}</p>
                </div>

                <div className="sub-text">
                  <p style={{ marginTop: '20px', lineHeight: "25px", fontWeight:"500" }}>{`Men only pay when they get a match`}</p>
                </div>

              </div>
              {/* End .col */}
            </div>
            <FancyFeatureTenOne />
            {/* End Fancy Feature Ten */}
          </div>
          <img
            src="images/shape/137.svg"
            alt="shape"
            className="shapes shape-one"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>


      <div className="fancy-feature-ten pt-80 md-pt-50" id="ppm">
        <div className="bg-wrapper" style={{ backgroundColor: '#ffecec', borderRadius: '0px' }}>
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-md-12"
              >
                <div className="title-style-six" style={{marginTop:'-20px'}}>
                  <h2>
                    <p style={{ marginTop: '0px', fontFamily: 'sans-serif', color: '#3d0000', marginBottom:'30px', lineHeight:'50px' }}>How other apps make money !!!</p>
                  </h2>
                </div>
                {/* /.title-style-six */}
              </div>
              {/* End .col */}

              {/* End .col */}
            </div>
            <FancyFeatureTen />
            {/* End Fancy Feature Ten */}
          </div>
          <img
            src="images/shape/137.svg"
            alt="shape"
            className="shapes shape-one"
          />
        </div>
        {/* /.bg-wrapper */}
      </div>


      {/* /.fancy-feature-nineteen */}

      <div className="fancy-feature-four mt-50" id="algo">
        <div className="bg-wrapper">
          <img
            src="images/shape/18.svg"
            alt="shape"
            className="shapes shape-right"
          />
          <img
            src="images/shape/19.svg"
            alt="shape"
            className="shapes shape-left"
          />
          <div className="container">
            <div className="title-style-two text-center mb-100 md-mb-50">
              <div className="row">
                <div className="col-xl-8 col-lg-9 col-md-10 m-auto">
                  {/* <p>What we do</p> */}
                  <h2>
                    {/* {`Our `} */}
                    <span>
                      {`Our Promise`}
                      <img src="images/shape/line-shape-2.svg" alt="shape" style={{ height: '55px', marginBottom: '-30px' }} />
                    </span>
                  </h2>
                </div>
                {/* End .col */}
              </div>
            </div>
            {/* End .title */}

            <div className="inner-content">
              <img
                src="images/shape/20.svg"
                alt="shape"
                className="shapes shape-one"
              />
              <img
                src="images/shape/21.svg"
                alt="shape"
                className="shapes shape-two"
              />
              <FeatureFour />
            </div>
            {/* End .inner-content */}
          </div>
        </div>
      </div>


      <div className="fancy-feature-six mt-140 md-mt-70" id="here">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="shapes shape-five"></div>
          <img
            src="images/shape/51.svg"
            alt="shape"
            className="shapes shape-six"
          />
          <div className="container">
            <div className="title-style-two text-center mb-85 md-mb-40">
              <h2>
                <span>
                  We don't do that here
                </span>
              </h2>
            </div>
            {/* End .title */}
            <FancyFeatureNinteen />
          </div>
        </div>
      </div>


      {/* 	=============================================
				Fancy Feature Twenty
			==============================================  */}
      {/* <div className="fancy-feature-twenty mt-225 md-mt-150">
        <div className="container">
          <div className="block-style-twenty">
            <FacnyVideoTwo />
          </div>
          

          <div className="block-style-twenty mt-170 md-mt-120">
            <FacnyVideoThree />
          </div>
          
        </div>
      </div>
       */}

      {/* 	=====================================================
				Fancy Feature Twenty One
			===================================================== */}
      {/* <div className="fancy-feature-twentyOne mt-200 md-mt-100" id="template">
        <div className="container">
          <div
            className="title-style-eight text-center mb-80 md-mb-30"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h2>Ready Stunning Templates</h2>
            <p>Quick and easy to customize. No experience required.</p>
          </div>
        </div>

        <div className="lg-container">
          <div className="container">
            <VideoGallery />

            <div
              className="text-center mt-70"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <a href="#" className="theme-btn-nine">
                Start Building
              </a>
            </div>
          </div>
        </div>
        
      </div> */}
      {/* /.fancy-feature-twentyOne */}

      {/* =====================================================
				Client Feedback Slider Six
			===================================================== */}
      {/* <div
        className="client-feedback-slider-six pt-170 md-pt-120"
        id="feedback"
      >
        <div className="inner-container">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-7 col-lg-9 m-auto"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <div className="title-style-eight text-center">
                  <h6>FEEDBACK</h6>
                  <h2>Trusted by millions of creators.</h2>
                </div>
              </div>
            </div>
            
          </div>
          
          <div className="clientSliderSix">
            <TestimonialSeven />
          </div>
        </div>
        
      </div>
       */}

      {/* 	=====================================================
				Fancy Short Banner Nine
			===================================================== */}
      <div className="fancy-short-banner-nine mt-110 md-mt-40">
        <div className="container">
          <div className="row">
            <div
              className="col-xl-8 col-lg-11 m-auto"
            >
              <div className="title-style-seven text-center">
                <h2 style={{ marginTop: '-30px' }}>Online dating is broken, We are here to fix it</h2>

                <p style={{ marginTop: '40px', marginBottom: '30px', fontSize: '30px', lineHeight: '40px' }}>Help us make the dating world a better place</p>

                <p style={{ marginTop: '60px', marginBottom: '0px', fontSize: '20px', lineHeight: '10px' }}>#TheMagicalDatingApp</p>
              </div>
              {/*  /.title-style-six */}
            </div>
          </div>
          {/* End .row */}

          <div className="hero-banner-ten" id="home">
            <div className="container">
              <div className="d-sm-flex align-items-center justify-content-center button-group">
                <a
                  href="#"
                  className="d-flex align-items-center ios-button"
                >
                  <img src="images/icon/apple.svg" alt="icon" className="icon" />
                  <div>
                    <span>Download on the</span>
                    <strong>App store</strong>
                  </div>
                </a>
                <a
                  href="#"
                  className="d-flex align-items-center windows-button"
                >
                  <img
                    src="images/icon/playstore.svg"
                    alt="icon"
                    className="icon"
                  />
                  <div>
                    <span>Get it on</span>
                    <strong>Google play</strong>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </div>
        {/*  /.container */}
        <img
          src="images/shape/177.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/178.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/*  /.fancy-short-banner-nen */}

      {/* =====================================================
				Footer Style Seven
			===================================================== */}
      <footer className="theme-footer-seven mt-20 md-mt-0">
        <div className="lg-container">
          {/* <div className="container">
            <FooterSeven />
          </div> */}

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default VideoEditorLanding;

import React from "react";
import { Helmet } from "react-helmet";
import HeaderLandingEditor from "components/header/landing/HeaderLandingEditor";
//import Footer from "../../../../components/footer/Footer";
import CopyRightThree from "components/footer/CopyRightThree";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Privacy & Terms
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderLandingEditor />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy" style={{ borderBottom: '0px' }}>
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Privacy Policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Terms of Service
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a className="nav-link" href="#opt3">
                      3. Privacy policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt4">
                      4. Cookie policy
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt5">
                      5. Third Party Links
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt6">
                      6. GDPR
                    </a>
                  </li> */}
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Privacy Policy</h2>
                    <div className="update-date">LAST UPDATED: 2024/06/01</div>
                    {/* <h3>1. Introduction</h3>
                    <p>
                      We have taken a number of steps to comply with the GDPR.
                      For more details about our compliance and how we handle
                      your data please check our latest legal documents below:
                    </p>
                    <ul>
                      <li>
                        <a href="#">Privacy policy</a>
                      </li>
                      <li>
                        <a href="#">Terms and conditions</a>
                      </li>
                    </ul>
                    <h3>2. About us</h3>
                    <p>
                      When you use Ticket Tailor for selling tickets and
                      collecting data from your attendees you are the “data
                      controller” of all your attendees’ data. We are a “data
                      processor”, which means that we won’t do anything with
                      your attendees’ data other than what you need us to in
                      order to provide our service. We won’t be sending your
                      attendees any marketing emails, sharing their data with
                      anyone and if you want us to delete the data, just ask.
                      It’s your data. 100
                    </p>
                    <h3>3. Accessing our Website</h3> */}
                    <p>
                      Your privacy is important to us. It is MingleSea's policy to respect your privacy and comply with
                      any applicable law and regulation regarding any personal information we may collect about you,
                      including via our MingleSea app on Android and iOS, MingleSea website, and/or any associated services.
                    </p>
                    <p>
                      Personal information is any information about you which can be used to identify you. This includes
                      information about you as a person which may include but is not limited to your name, address and date of birth, your devices, payment details,
                      and even information about how you use an app or online service.
                    </p>
                    <p>
                      In the event our app or website contains links to third-party sites and services, please be aware that those sites and
                      services have their own privacy policies. After following a link to any third-party content, you should read
                      their posted privacy policy information about how they collect and use personal information. This Privacy
                      Policy does not apply to any of your activities after you leave our app or website.
                    </p>
                    <h3>Information We Collect</h3>
                    <p>
                      Information we collect falls into one of two categories: 'voluntarily provided' information and
                      'automatically collected' information.

                      'Voluntarily provided' information refers to any information you knowingly and actively provide
                      us when using our app and its associated services.

                      'Automatically collected' information refers to any information automatically sent by your device
                      in the course of accessing our app and its associated services.
                    </p>
                    <h3>Log Data</h3>
                    <p>
                      When you access our servers via our app, we may automatically log the standard data provided by your device.
                      It may include your device's Internet Protocol (IP) address, your device type and version, your activity within
                      the app, time and date, and other details about your usage.

                      Additionally, when you encounter certain errors while using the app, we may automatically collect data about the
                      error and the circumstances surrounding its occurrence. This data may include technical details about your
                      device, what you were trying to do when the error happened, and other technical information relating to the
                      problem. You may or may not receive notice of such errors, even in the moment they occur, that they have occurred,
                      or what the nature of the error is.

                      Please be aware that while this information may not be personally identifying by itself, it may be possible to
                      combine it with other data to personally identify individual persons.
                    </p>
                    <h3>Device Data</h3>
                    <p>
                      Our app may access and collect data via your device's in-built tools, which may include but is not limited to:

                      Location data,
                      Camera,
                      Storage, photos and/or media,
                      Notifications,
                      Mobile data.

                      When you install our app or use your device’s tools within our app, we request permission to access this information.
                      The specific data we collect can depend on the individual settings of your device and the permissions you grant when
                      you install and use the app.
                    </p>
                    <h3>Personal Information</h3>
                    <p>
                      We may ask for personal information — for example, when you submit content to us or when you contact us —
                      which may include but is not limited to one or more of the following:

                      Email,
                      Phone/mobile number,
                      Age,
                      Height,
                      Star sign,
                      Drinking habits,
                      Smoking habits,
                      Drug use habits,
                      Exercise habits,
                      Yearly Income,
                      Number of kids,
                      Education level,
                      Religion,
                      Marital Status,
                      Dating purpose,
                      Political affiliation,
                      City of residence.
                    </p>
                    <h3>Sensitive Personal Information</h3>
                    <p>We may request or collect information that may be considered sensitive under applicable laws, such as information
                      about your racial or ethnic origins, sexual orientation, or religious beliefs. This information will only be collected
                      if you voluntarily choose to provide it and will be processed solely for the purpose of enhancing your experience on MingleSea.
                      By providing such information, you consent to its processing under this privacy policy. You may edit or delete this
                      information at any time through your account settings.
                    </p>
                    <h3>Cross-Border Data Transfers</h3>
                    <p>To provide you with our services, we may store and process your personal information in locations outside of your home country.
                      Specifically, your data may be stored and processed in the United States and Singapore. By using our app and providing us
                      with your personal information, you consent to this transfer, storage, and processing of your data in these locations.
                      We ensure that any transfer of your data is carried out in accordance with applicable laws and that your personal information
                      is protected through safeguards such as encryption, secure storage protocols, and strict access controls.
                    </p>
                    <h3>Automated Decision-Making and Profiling</h3>
                    <p>Our app uses automated decision-making processes to suggest potential matches based on the information you provide in your profile,
                      such as your preferences and activity within the app. These automated decisions are designed to enhance your experience and help
                      you connect with users who align with your stated preferences.We do not engage in any profiling or predictive analytics beyond
                      the automated recommendations described above. Specifically, we do not evaluate or infer sensitive aspects about your personality,
                      behavior, or preferences beyond the information you explicitly provide.
                    </p>
                    <h3>No Data Sharing for Marketing or External Use of AI</h3>
                    <p>We do not share your personal information with any third parties for their direct marketing purposes. Your data is used
                      solely to provide and improve our services, and we take strict measures to ensure it is not disclosed for marketing
                      activities outside of our app.Additionally, MingleSea does not collaborate with or share user data with
                      artificial intelligence (AI) vendors for any purpose. Any personalization or recommendations within the app are
                      based exclusively on the information you provide and the activities you perform within the platform. Your data is
                      processed securely and solely within the scope of MingleSea’s operations, ensuring your privacy remains uncompromised.
                    </p>
                    <h3>Fraud Protection and User Verification</h3>
                    <p>At MingleSea, we prioritize creating a safe and secure environment for all users. To prevent fraudulent or unauthorized activities
                      and ensure the integrity of our platform, we implement several measures, including but not limited to: Email Verification: Users must
                      verify their email addresses during account registration to confirm their identity and ensure a valid communication channel. Phone
                      Number Verification: We require users to verify their phone numbers as an additional layer of authentication and to minimize the
                      risk of fake accounts. Profile Photo Review: Our human content moderators manually review profile photos to ensure they comply with
                      our community guidelines and to detect any suspicious or inappropriate content. Verification Photo: Users will be asked to submit a
                      real-time verification photo by following specific instructions, such as a particular pose. This photo is reviewed to ensure it matches
                      the user's profile photos and to confirm the authenticity of the account. These measures are implemented to protect your experience
                      on MingleSea and ensure that the platform remains a trustworthy space for meaningful connections. Information collected as part of
                      these processes is handled securely and in accordance with this privacy policy.
                    </p>
                    <h3>Cookies and Tracking Technologies</h3>
                    <p>MingleSea does not use cookies, web beacons, or similar tracking technologies to monitor your activity or store data about your usage.
                      We are committed to providing a straightforward experience without relying on external tracking mechanisms. Any data we collect is
                      provided directly by you or gathered automatically by your device in accordance with this privacy policy, and we do not employ
                      tracking technologies to collect additional information about your behavior or preferences.
                    </p>
                    <h3>User-Generated Content</h3>
                    <p>
                      We consider 'user-generated content' to be materials (text, image and/or video content) voluntarily supplied
                      to us by our users for the purpose of publication on our app or re-publishing on our social media channels.
                      All user-generated content is associated with the account or email address and phone number used to submit the materials.

                      Please be aware that any content you submit for the purpose of publication will be public after posting
                      (and subsequent review or vetting process). Once published it may be accessible to third parties not
                      covered under this privacy policy.
                    </p>
                    <h3>Legitimate Reasons for Processing Your Personal Information</h3>
                    <p>
                      We only collect and use your personal information when we have a legitimate reason for doing so.
                      In which instance we only collect personal information that is reasonably necessary to provide our services to you.
                    </p>
                    <h3>Collection and Use of Information</h3>
                    <p>
                      We may collect personal information from you when you do any of the following on our app:
                      Register for an account,
                      Complete your profile in our app,
                      Use a mobile device or web browser to access our content,
                      Contact us via email, social media, or on any similar technologies,
                      When you mention us on social media,

                      We may collect, hold, use and disclose information for the following purposes,
                      and personal information will not be further processed in a manner that is incompatible with these purposes:

                      to provide you with our app's core features and services,

                      to enable you to customize or personalize your experience of our app,

                      to contact and communicate with you,

                      for analytics, market research, and business development, including to operate and improve our app,
                      associated applications, and associated social media platforms,

                      for advertising and marketing, including to send you promotional information about our products and
                      services and information about third parties that we consider may be of interest to you,

                      to enable you to access and use our app, associated platforms, and associated social media channels,

                      for internal record keeping and administrative purposes,

                      to comply with our legal obligations and resolve any disputes that we may have,

                      to attribute any content (e.g. posts and comments) you submit that we publish on our app,

                      for security and fraud prevention, and to ensure that our sites and apps are safe,
                      secure, and used in line with our terms of use,

                      for technical assessment, including to operate and improve our app, associated applications,
                      and associated social media platforms.

                      We may combine voluntarily provided and automatically collected personal information with general
                      information or research data we receive from other trusted sources. For example, If you consent to
                      us accessing your social media profiles, we may combine information sourced from those profiles with
                      information received from you directly to provide you with an enhanced experience of our app and services.
                    </p>
                    <h3>Security of Your Personal Information</h3>
                    <p>
                      When we collect and process personal information, and while we retain this information,
                      we will protect it within commercially acceptable means to prevent loss and theft, as
                      well as unauthorized access, disclosure, copying, use or modification.

                      Although we will do our best to protect the personal information you provide to us,
                      we advise that no method of electronic transmission or storage is 100% secure and no
                      one can guarantee absolute data security.

                      You are responsible for selecting any password and its overall security strength,
                      ensuring the security of your own information within the bounds of our services.
                      For example, ensuring any passwords associated with accessing your personal information
                      and accounts are secure and confidential.
                    </p>
                    <h3>How Long We Keep Your Personal Information</h3>
                    <p>
                      We keep your personal information only for as long as we need to. This time period may
                      depend on what we are using your information for, in accordance with this privacy policy.
                      For example, if you have provided us with personal information as part of creating an account
                      with us, we may retain this information for the duration your account exists on our system.
                      If your personal information is no longer required for this purpose, we will delete it or
                      make it anonymous by removing all details that identify you.

                      However, if necessary, we may retain your personal information for our compliance with a legal,
                      accounting, or reporting obligation or for archiving purposes in the public interest, scientific,
                      or historical research purposes or statistical purposes.
                    </p>
                    <h3>Children’s Privacy</h3>
                    <p>
                      We do not aim any of our products or services directly at children under the age of 18, and we do not
                      knowingly collect personal information about children under 18. Although we cannot guarantee 100% accuracy, our content moderators
                      will prevent users from using our platform if they suspect the user is under 18 year of age. MingleSea also includes a reporting mechanism
                      within the app that allows users to report profiles they believe are in violation of our policies,
                      including suspected underage accounts. Reported profiles are reviewed by our moderation team,
                      and appropriate action is taken to ensure a safe community environment.
                    </p>
                    <h3>Legal Compliance</h3>
                    <p>We may disclose your personal information if required by law or as necessary to comply with legal obligations, regulatory requirements,
                      or lawful requests from government authorities. Any such disclosures will be conducted in accordance with applicable laws and regulations,
                      and we remain committed to protecting your privacy throughout the process.
                    </p>
                    <h3>Disclosure of Personal Information to Third Parties</h3>
                    <p>
                      We may disclose personal information to:

                      an affiliate of our company,

                      third-party service providers for the purpose of enabling them to provide their services including,
                      (without limitation) IT service providers, data storage, hosting and server providers, ad networks,
                      analytics, error loggers, debt collectors, maintenance or problem-solving providers,
                      professional advisors, and payment systems operators,

                      our employees, contractors, and/or related entities,

                      our existing or potential agents or business partners,

                      credit reporting agencies, courts, tribunals, and regulatory authorities, in the event you fail
                      to pay for goods or services we have provided to you,

                      courts, tribunals, regulatory authorities, and law enforcement officers, as required by law,
                      in connection with any actual or prospective legal proceedings, or in order to establish,
                      exercise, or defend our legal rights,

                      third parties, including agents or sub-contractors who assist us in providing information,
                      products, services, or direct marketing to you,

                      third parties to collect and process data,

                      an entity that buys, or to which we transfer all or substantially all of our assets and business,

                      Third parties we currently use include:

                      Google, Apple, Microsoft, RevenueCat, expo.dev, CloudFlare, Digital Ocean, MongoDB Atlas, SendGrid, SMS123, Zoho Desk.
                      
                    </p>
                    <h3>Providing Personal Information</h3>
                    <p>
                      By providing personal information to us, you understand we will collect, hold, use, and disclose
                      your personal information in accordance with this privacy policy. You do not have to provide personal
                      information to us, however, if you do not, it will affect your use of our app or the products and/or
                      services offered on or through it.
                    </p>
                    <h3>Information from third parties (Personal Information)</h3>
                    <p>
                      If we receive personal information about you from a third party, we will protect it as set out in
                      this privacy policy. If you are a third party providing personal information about somebody else,
                      you represent and warrant that you have such person’s consent to provide the personal information to us.
                    </p>
                    <h3>Marketing permission (Personal Information)</h3>
                    <p>
                      If you have previously agreed to us using your personal information for direct marketing purposes,
                      you may change your mind at any time by contacting us using our contact details below.
                    </p>
                    <h3>Access (Personal Information)</h3>
                    <p>
                      You may request details of the personal information that we hold about you, and in order for us to
                      process your request, you will need to email us a copy of your IC and a selfie so we can verify it is you.
                      It may take us up to 30 days to process your request and we will send you your data via email.
                    </p>
                    <h3>Correction (Personal Information)</h3>
                    <p>
                      If you believe that any information we hold about you is inaccurate, incorrect, out of date, incomplete,
                      irrelevant, or misleading, please use our app and correct your information yourself.
                      We do not chage any personal information on behalf of our users.
                    </p>
                    <h3>Non-discrimination</h3>
                    <p>
                      We will not discriminate against you for exercising any of your rights over your personal information.
                      Unless your personal information is required to provide you with a particular service or offer
                      (for example serving particular content to your device), we will not deny you goods or services
                      and/or charge you different prices or rates for goods or services, including through granting
                      discounts or other benefits, or imposing penalties, or provide you with a different level or
                      quality of goods or services.
                    </p>
                    <h3>Notification of data breaches</h3>
                    <p>
                      We will comply with laws applicable to us in respect of any data breach.
                    </p>
                    <h3>Complaints</h3>
                    <p>
                      If you believe that we have breached a relevant data protection law and wish to make a complaint,
                      please contact us using the details below and provide us with full details of the alleged breach.
                      We will promptly investigate your complaint and respond to you, in writing, setting out the outcome
                      of our investigation and the steps we will take to deal with your complaint. You also have the right
                      to contact a regulatory body or data protection authority in relation to your complaint.
                    </p>
                    <h3>Unsubscribe</h3>
                    <p>
                      To unsubscribe from our email database or opt-out of communications (including marketing communications),
                      please contact us using the details provided in this privacy policy, or opt-out using the opt-out facilities
                      provided in the communication. We may need to request specific information from you to help us confirm your identity.
                    </p>
                    <h3>Business Transfers</h3>
                    <p>
                      If we or our assets are acquired, or in the unlikely event that we go out of business or enter bankruptcy,
                      we would include data, including your personal information, among the assets transferred to any parties who
                      acquire us. You acknowledge that such transfers may occur, and that any parties who acquire us may, to the
                      extent permitted by applicable law, continue to use your personal information according to this policy,
                      which they will be required to assume as it is the basis for any ownership or use rights we have over
                      such information.
                    </p>
                    <h3>Limits of Our Policy</h3>
                    <p>
                      Our app may link to external sites that are not operated by us. Please be aware that we have no
                      control over the content and policies of those sites, and cannot accept responsibility or liability
                      for their respective privacy practices.
                    </p>
                    <h3>Changes to This Policy</h3>
                    <p>
                      At our discretion, we may change our privacy policy to reflect updates to our business processes,
                      current acceptable practices, or legislative or regulatory changes. If we decide to change this
                      privacy policy, we will post the changes here.

                      If the changes are significant, or if required by applicable law, we will contact you
                      (based on your selected preferences for communications from us) and all our registered users
                      with the new details and links to the updated or changed policy.

                      If required by law, we will get your permission or give you the opportunity to opt in to or opt out of,
                      as applicable, any new uses of your personal information.
                    </p>
                    <h3>Contact Us</h3>
                    <p>
                      For any questions or concerns regarding your privacy, you may contact us using the following details:
                    </p>
                    <p>
                      MingleSea Support
                      <br />
                      support@minglesea.com
                    </p>

                  </div>

                  <br />
                  <br />
                  <br />
                  <br />
                  <br />

                  <div id="opt2">
                    <h2 className="font-gilroy-bold">Terms of Service</h2>
                    <div className="update-date" style={{ paddingLeft: '5px' }}>LAST UPDATED: 2024/06/01</div>
                    <p>
                      These Terms of Service govern your use of MingleSea, our website located at{" "}
                      <a href="https://minglesea.com">https://minglesea.com</a> and any related services provided by MingleSea.
                    </p>
                    <p>
                      When you create a MingleSea account or use MingleSea, you agree to abide by these Terms of Service and
                      to comply with all applicable laws and regulations. If you do not agree with these Terms of Service,
                      you are prohibited from further using the app, accessing our website, or using any other services provided
                      by MingleSea.
                    </p>
                    <p>
                      If you access or download MingleSea from (1) the Apple App Store, you agree to any Usage Rules set forth
                      in the App Store Terms of Service; and/or (2) the Google Play Store, you agree to the Android, Google Inc.
                      Terms and Conditions including the Google Apps Terms of Service.
                    </p>
                    <p>We, MingleSea, reserve the right to review and amend any of these Terms of Service at our sole discretion.
                      Upon doing so, we will update this page and may and notify you through the app and/or the email address you provided
                      when you created your account. Any changes to these Terms of Service will take effect immediately from the date
                      of publication.
                    </p>
                    <h3>Limitations of Use</h3>
                    <p>
                      By using MingleSea and our website, you warrant on behalf of yourself, any entity who you represent who has entered
                      into these Terms of Service, that you will not:

                      modify, copy, prepare derivative works of, decompile, or reverse engineer MingleSea or any materials and software
                      contained within MingleSea or on our website;

                      remove any copyright or other proprietary notations from MingleSea or any materials and software contained within
                      MingleSea or on our website;

                      transfer MingleSea or any of its associated materials to another person or "mirror" the materials on any other server;

                      knowingly or negligently use MingleSea or any of its associated services in a way that abuses or disrupts our networks
                      or any other service MingleSea provides;

                      use MingleSea or its associated services to transmit or publish any harassing, indecent, obscene, fraudulent,
                      or unlawful material;

                      use MingleSea or its associated services in violation of any applicable laws or regulations;

                      use MingleSea to send unauthorized advertising or spam;

                      harvest, collect, or gather user data with or without the user’s consent; or

                      use MingleSea or its associated services in such a way that may infringe the privacy, intellectual property rights,
                      or other rights of third parties.
                    </p>


                    <h3>Eligibility Requirements</h3>
                    <p>
                      To use MingleSea, you must be at least 18 years old. By creating an account or using our services, you confirm that:
                      You are legally able to form a binding agreement with MingleSea.You are not prohibited from using our services under
                      applicable laws in your jurisdiction or any other jurisdiction.You will comply with these Terms of Service and all
                      applicable local, state, national, and international laws, regulations, and rules.You have not been convicted of
                      or pled no contest to any felony, sexual offense, or crime involving violence, and you are not required to register
                      as a sex offender with any government authority. If you do not meet these requirements, you are not authorized to access or use MingleSea.
                    </p>


                    <h3>Account Security and Responsibility</h3>
                    <p>
                      When registering or logging into MingleSea, you must provide both a valid email address and a valid phone number and create a strong password.
                      You are solely responsible for maintaining the confidentiality and security of your login credentials, including your password.
                      When using MingleSea you agree to: Choose a strong password and keep it private. Not share your login credentials with anyone else.
                      Accept full responsibility for all activities conducted under your account. MingleSea will not be held liable for any unauthorized
                      access or actions taken in your account due to your failure to protect your credentials. If you suspect that someone has gained
                      unauthorized access to your account, you must delete your account immediately. We will not help you with password recovery, however
                      if your password has been changed, you can create a new password as long as you have access to your registered email address and phone number.
                    </p>



                    <h3>Modifications to the Service and Termination</h3>
                    <p>
                      MingleSea continuously seeks to improve its services and features. As a result, we may introduce new features, enhance existing ones,
                      or remove certain features without prior notice if these changes do not significantly affect your rights or obligations. In cases where
                      changes may impact you, we will endeavor to notify you through the app or your registered email address. We also reserve the right to
                      discontinue the service entirely, with advance notice unless urgent safety or security concerns prevent us from doing so. You may delete
                      your MingleSea account at any time by following the steps in the app's settings page. MingleSea reserves the right to suspend or terminate
                      your account at its sole discretion without notice if you violate these Terms of Service or engage in any behavior deemed harmful to the
                      platform or other users. No refunds will be issued for any services or features purchased in such cases. Certain sections of these
                      Terms of Service will remain in effect even after your account is terminated, including but not limited to provisions regarding
                      intellectual property, liability, and governing law.
                    </p>

                    <h3>Safety Provisions and User Interactions</h3>
                    <p>
                      MingleSea strives to promote a safe and positive environment for all users but cannot guarantee the behavior or actions of any
                      individual on or off the platform. By using MingleSea, you agree to: Exercise caution in all interactions with other users,
                      especially when communicating outside the app or arranging to meet in person. Never share sensitive financial information
                      (e.g., credit card or bank account details) with other users or send them money under any circumstances. MingleSea does not
                      conduct criminal background checks or verify the identity of its users. We make no representations or warranties about the
                      character, background, or intentions of individuals you interact with through the platform. You acknowledge that you are
                      solely responsible for your interactions and dealings with other users. MingleSea reserves the right to perform criminal
                      background checks or other screenings at any time using publicly available records, should it deem necessary. By using the
                      service, you agree that we may conduct such checks, though we are under no obligation to do so.
                    </p>

                    <h3>Rights and Restrictions</h3>
                    <p>
                      MingleSea grants you a personal, non-transferable, non-exclusive, revocable, worldwide license to access and use our services
                      for personal, non-commercial purposes in accordance with these Terms of Service. This license is intended solely for you to
                      enjoy the features and benefits of MingleSea as we intend. You agree that you will not: Use MingleSea or any content provided
                      within the app for commercial purposes without prior written consent from MingleSea. Copy, modify, distribute, or create
                      derivative works from any content, materials, trademarks, or intellectual property accessible through the app without explicit
                      permission. Imply that MingleSea endorses your statements or activities without our prior written consent. Use any automated
                      tools or methods (e.g., bots, scrapers, or crawlers) to access, retrieve, or manipulate data from the app. Engage in any activity
                      that could interfere with or harm the service, its systems, or networks. Upload viruses, malware, or any other harmful software
                      that could compromise the app’s security. Modify, reverse engineer, or disassemble any part of MingleSea or its associated services.
                      Frame, mirror, or reference MingleSea content to redirect users to other websites or platforms without permission. Test, probe, or
                      exploit vulnerabilities in MingleSea’s systems or networks. Promote or participate in activities that violate these Terms of Service.
                      MingleSea reserves the right to investigate and take appropriate action against any unauthorized or illegal use of the service,
                      which may include account suspension or termination. Additionally, MingleSea may periodically provide updates, enhancements,
                      or new features to improve the app’s functionality. By using MingleSea, you grant us permission to download and install these
                      updates automatically.
                    </p>


                    <h3>Rights You Grant MingleSea</h3>
                    <p>
                      By creating an account on MingleSea, you grant us a non-exclusive, worldwide, royalty-free, transferable, and sublicensable license to host,
                      store, use, copy, display, reproduce, adapt, modify, publish, and distribute any content you post, upload, share, or otherwise make
                      available through the platform (collectively, "Content"). This license is necessary to operate, improve, and develop our services.
                      You retain ownership of your Content. However, this license allows MingleSea to: Use your Content for purposes such as advertising,
                      marketing, or promoting the service. Create derivative works using your Content (e.g., screenshots of the service). If third parties
                      misuse your Content outside the service, you grant MingleSea the right, but not the obligation, to take action on your behalf,
                      including issuing takedown notices under copyright law. You are responsible for maintaining the confidentiality of your account
                      credentials and the security of your data. MingleSea is not liable for breaches resulting from your failure to safeguard this
                      information. By using the platform, you acknowledge that your data may be processed in accordance with our Privacy Policy.
                      MingleSea reserves the right to monitor and review any Content shared on the platform and may remove or modify any material that
                      violates these Terms of Service or damages the service’s reputation.
                    </p>

                    <h3>Community Rules</h3>
                    <p>
                      By using MingleSea, you agree to follow these Community Rules to ensure a safe and respectful environment for all users. You agree that you
                      will not: Use MingleSea for any illegal or unauthorized activities. Engage in any behavior or activity that is harmful, abusive, or
                      disruptive to the platform or its users. Post, upload, or share content that: Violates the rights of others, including intellectual
                      property rights, privacy, or contractual obligations. Contains hate speech, threats, sexually explicit material, or incites violence.
                      Encourages racism, bigotry, or physical harm to individuals or groups. Includes nudity, graphic violence, or any other objectionable
                      material. Harass, bully, stalk, intimidate, or defame other users in any way. Impersonate any person or entity or post photos or
                      information about another person without their explicit consent. Solicit money, passwords, or other personal identifying information
                      from other users for commercial or illegal purposes. Use another user’s account, share your account, or create multiple accounts for
                      deceptive purposes. Attempt to create a new account if your existing account has been terminated without prior approval from MingleSea.
                      If you violate these Community Rules or otherwise misuse MingleSea, we reserve the right to investigate and take appropriate action,
                      including suspending or terminating your account without notice or refund. MingleSea may also remove or modify any content that violates
                      these rules or the Terms of Service to maintain a safe and respectful environment for all users.
                    </p>

                    <h3>Content Responsibility and Reporting</h3>
                    <p>
                      Content uploaded or shared by users on MingleSea is the sole responsibility of the user who provides it. While we reserve the right to
                      review, monitor, and remove content that violates these Terms of Service, we cannot guarantee that all content on the platform will
                      comply with our guidelines or be appropriate for all users. If you encounter content on MingleSea that you believe violates these
                      Terms of Service, you are encouraged to report it through the platform’s reporting tools or by contacting us directly. We will review
                      all reports and take appropriate action as needed to maintain a safe and respectful environment.
                    </p>

                    <h3>Purchases</h3>
                    <p>
                      MingleSea offers in-app purchases available directly within the app. By making an in-app purchase, you agree that your chosen
                      payment provider (e.g., your credit card or linked account through Apple or Google) will process the payment at the displayed
                      price, including any applicable taxes. All purchases made within MingleSea are final and non-refundable. MingleSea is not obligated
                      to provide refunds or credits for any reason, including but not limited to unused purchases or account termination. Any virtual items
                      or quotas purchased within MingleSea are non-transferable and may only be used within the app. These items do not represent real-world
                      monetary value and are provided solely for use in MingleSea. MingleSea reserves the right to manage, regulate, or modify in-app purchases
                      and virtual items at any time. This may include adjustments to pricing, availability, or functionality. By using the service,
                      you acknowledge that MingleSea has no liability to you or any third party for such changes. If payment is unsuccessful due to expiration,
                      insufficient funds, or other reasons, you remain responsible for any outstanding amounts. By completing a purchase, you agree to these terms
                      and the payment policies set forth by Apple and Google regarding in-app transactions.
                    </p>

                    <h3>Disclaimers</h3>
                    <p>
                      MingleSea provides the service on an "as is" and "as available" basis and, to the extent permitted by applicable law, makes no
                      warranties of any kind, whether express, implied, statutory, or otherwise, regarding the service (including all content contained
                      within it). This includes, without limitation, any implied warranties of merchantability, fitness for a particular purpose,
                      non-infringement, or satisfactory quality. MingleSea makes no guarantees or assurances that: a. the service will be uninterrupted,
                      secure, or error-free; b. any defects or errors in the service will be corrected; or c. any content or information you obtain through
                      the service will be accurate, reliable, or complete. MingleSea assumes no liability for: Content posted, sent, or received through the
                      service by you, any other user, or any third party. Damages to your device, software, or data, including but not limited to malware
                      infections, data corruption, or hardware issues, resulting from your use of the app. Any unauthorized access, use, or alteration of
                      your content. Any material downloaded or otherwise obtained through the use of the service is accessed at your own discretion and risk.
                      MingleSea disclaims and takes no responsibility for any conduct, interactions, or communications of you or any other user,
                      whether on or off the service.
                    </p>

                    <h3>Third-Party Services</h3>
                    <p>
                      MingleSea may include advertisements, promotions, or links to third-party websites, services, or resources. MingleSea does not guarantee
                      the availability, reliability, or content of these external websites or resources. Your interactions with third parties, including their
                      terms, policies, and practices, are solely between you and the third party. MingleSea is not responsible for the terms, behavior, or any
                      consequences arising from your interactions with third parties. Use of these third-party services is entirely at your own risk, and we
                      strongly recommend reviewing their terms and policies before engaging with them.
                    </p>


                    <h3>Indemnity</h3>
                    <p>
                      You agree to indemnify, defend, and hold harmless MingleSea, its affiliates, and their respective officers, directors, agents, employees,
                      and partners from and against any and all complaints, demands, claims, damages, losses, costs, liabilities, and expenses, including
                      attorney's fees. This indemnity applies to any claims arising out of, relating to, or resulting from: Your access to or use of MingleSea,
                      its website, or associated services. Any content you submit, post, or transmit through MingleSea. Your violation of these Terms of Service.
                      Your breach of any applicable laws, regulations, or the rights of a third party. This indemnity remains effective to the fullest extent
                      permitted by applicable law.
                    </p>


                    <h3>User Feedback and Suggestions</h3>
                    <p>
                      By submitting feedback, ideas, or suggestions to MingleSea, you agree that such submissions are non-confidential and grant MingleSea a
                      royalty-free, worldwide, perpetual, irrevocable, transferable, and sublicensable license to use, modify, distribute, and publish your
                      feedback for any purpose, including improving the platform or developing new features. You acknowledge that MingleSea is not obligated
                      to compensate you for your submissions or implement your suggestions, and MingleSea retains sole discretion regarding the use of
                      your feedback.
                    </p>




                    <h3>Intellectual Property</h3>
                    <p>
                      The intellectual property in the materials in MingleSea and on our website are owned by or licensed to MingleSea.
                      You may download MingleSea to view, use, and display the application on your mobile device for your personal use only.

                      This constitutes the grant of a license, not a transfer of title. This license shall automatically terminate if you
                      violate any of these restrictions or these Terms of Service and can be terminated by MingleSea at any time without any prior notice.
                    </p>
                    <h3>User-Generated Content</h3>
                    <p>
                      You retain your intellectual property ownership rights over content you submit to us for publication within MingleSea
                      and/or on its corresponding website. We will never claim ownership of your content but we do require a license from
                      you in order to use it.

                      When you use MingleSea or its associated services to post, upload, share or otherwise transmit content covered by
                      intellectual property rights, you grant to us a non-exclusive, royalty-free, transferable, sub-licensable,
                      worldwide license to use, distribute, modify, run, copy, publicly display, translate or otherwise create
                      derivative works of your content in a manner that is consistent with your privacy preferences and our Privacy Policy.

                      The license you grant us can be terminated at any time by deleting your content or account. However, to the extent
                      that we (or our partners) have used your content in connection with commercial or sponsored content, the license
                      will continue until the relevant commercial or post has been discontinued by us.

                      You give us permission to use your username, phone number, email address and other identifying information associated with your account in
                      a manner that is consistent with your privacy preferences and our Privacy Policy.
                    </p>
                    <h3>Automatic Updates</h3>
                    <p>
                      You give us permission to download and install updates to MingleSea on your device in accordance
                      with your privacy preferences. This permission can be revoked at any time by deleting MingleSea
                      from your device.
                    </p>
                    <h3>Liability</h3>
                    <p>
                      MingleSea and the materials in MingleSea and on our website are provided on an 'as is' basis. To the extent permitted by law, MingleSea
                      makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied
                      warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property, or other
                      violation of rights. In no event shall MingleSea or its suppliers be liable for any consequential loss suffered or incurred by you or
                      any third party arising from the use or inability to use MingleSea, our website, or any other services provided by MingleSea or the
                      materials in MingleSea, even if MingleSea or an authorized representative has been notified, orally or in writing, of the possibility
                      of such damage. In the context of this agreement, "consequential loss" includes any consequential loss, indirect loss, real or anticipated
                      loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of
                      reputation, loss of use, and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence),
                      indemnity, or otherwise. MingleSea does not guarantee that the service will be uninterrupted, secure, or error-free; that any defects
                      or errors will be corrected; or that any content or information obtained through the service will be accurate, reliable, or complete.
                      MingleSea is not liable for any unauthorized access, use, or alteration of your content. Any material downloaded or obtained through
                      the service is accessed at your own discretion and risk. To the extent permitted by law, MingleSea's total liability to you for all
                      claims arising out of or relating to the service or these Terms will not exceed the amount you paid, if any, to MingleSea in the
                      twelve months preceding the event that gave rise to the liability. Because some jurisdictions do not allow limitations on implied
                      warranties or liability for consequential or incidental damages, these limitations may not apply to you.
                    </p>
                    <h3>Accuracy of Materials</h3>
                    <p>
                      The materials appearing in MingleSea or on our website are not comprehensive and are for general information
                      purposes only. To the extent permitted by law, MingleSea does not warrant or make any representations
                      concerning the accuracy, likely results, or reliability of the use of the materials in MingleSea or on
                      our website, or otherwise relating to such materials or on any resources linked to MingleSea and our website.
                    </p>
                    <h3>Links</h3>
                    <p>
                      MingleSea has not reviewed all of the sites linked to MingleSea or on its corresponding website
                      and is not responsible for the contents of any such linked site. The inclusion of any link
                      does not imply endorsement, approval or control by MingleSea of the site. Use of any such linked
                      website is at your own risk and we strongly advise you make your own investigations with respect
                      to the suitability of those sites.
                    </p>
                    <h3>Notice regarding Apple</h3>
                    <p>
                      To the extent that you are using or accessing MingleSea on an iOS device, you acknowledge
                      and agree to the terms of this clause. You acknowledge that these Terms of Service are between
                      you and MingleSea only, not with Apple Inc. (Apple), and Apple is not responsible for MingleSea
                      and any materials available in MingleSea.

                      Apple has no obligation to furnish you with any maintenance and support services with respect to MingleSea.

                      If MingleSea fails to conform to any applicable warranty, you may notify Apple and Apple will refund the
                      purchase price of the mobile application to you. To the maximum extent permitted by applicable law,
                      Apple will have no other warranty obligation whatsoever with respect to MingleSea and any other claims,
                      losses, liabilities, damages, costs or expenses attributable to any failure to conform to any warranty
                      will be our responsibility.

                      Apple is not responsible for addressing any claims by you or any third party relating to MingleSea
                      or your use of MingleSea, including but not limited to (1) product liability claims; (2) any claim
                      that our mobile application fails to conform to any applicable legal or regulatory requirement;
                      and (3) claims arising under consumer protection or similar legislation.

                      Apple is not responsible for the investigation, defense, settlement and discharge of any third-party
                      claim that our mobile application infringes that third party’s intellectual property rights.

                      You agree to comply with any applicable third-party terms when using MingleSea,
                      including any Usage Rules set forth in the Apple App Store Agreement of Service.

                      Apple and Apple’s subsidiaries are third-party beneficiaries of these Terms of Service and,
                      upon your acceptance of these Terms of Service, Apple will have the right
                      (and will be deemed to have accepted the right) to enforce these Terms of Service
                      against you as a third-party beneficiary of these Terms of Service.

                      You hereby represent and warrant that (1) you are not located in a country that is subject
                      to a U.S. Government embargo, or that has been designated by the U.S. Government as a 'terrorist supporting'
                      country; and (2) you are not listed on any U.S. Government list of prohibited or restricted parties.
                    </p>
                    <h3>Emotional Well-Being</h3>
                    <p> MingleSea shall not be held responsible or liable for any emotional distress,
                      trauma, or psychological harm that may result from the use of our app. By using MingleSea, you acknowledge and
                      agree that your emotional well-being is your own responsibility, and that MingleSea makes no guarantees or warranties
                      regarding the emotional impact of using the app, including but not limited to, interactions with other users,
                      the content displayed, or the outcomes of using our services. In no event shall MingleSea be liable for any claims
                      related to emotional harm, whether direct, indirect, incidental, or consequential, arising out of your use or
                      inability to use the app.
                    </p>
                    <h3>User Interactions Outside of MingleSea</h3>
                    <p>
                      We take no responsibility and disclaim any liability for any physical or emotional harm, damages, or losses
                      that may occur if users choose to engage with each other outside of the MingleSea platform. Any in-person
                      meetings, communications, or other forms of engagement outside of the app are solely at the users' own risk.
                      MingleSea does not screen, verify, or endorse any user-generated interactions beyond the app's confines and
                      will not be liable for any outcomes resulting from such interactions, whether they be direct, indirect,
                      incidental, or consequential.
                    </p>
                    <h3>Right to Terminate</h3>
                    <p>
                      We reserve the right to suspend, terminate, block, or delete your MingleSea account and revoke your rights to use MingleSea and these
                      Terms of Service at any time, immediately and without written notice to you for any breach of these Terms of Service or for any
                      other reason we see fit. Additionally, MingleSea may terminate your account if required to comply with applicable laws, regulations,
                      or government directives. No refunds will be provided for any services or features purchased in such cases.
                    </p>
                    <h3>Refund Policy</h3>
                    <p>
                      Notwithstanding any circumstances, we will not provide any refunds for services purchased through Minglesea.
                    </p>
                    <h3>Communication Policy</h3>
                    <p>
                      We may, at our discretion, choose not to respond to communications from you. This decision is final and non-negotiable.
                      You agree not to pursue any legal action or claim against us for our choice to exercise this policy.
                    </p>
                    <h3>Severance</h3>
                    <p>
                      Any term of these Terms of Service which is wholly or partially void or unenforceable is
                      severed to the extent that it is void or unenforceable. The validity of the remainder of
                      these Terms of Service is not affected.
                    </p>


                    <h3>Governing Law</h3>
                    <p>
                      These Terms of Service are governed by and construed in accordance with the laws of any country we operate in.
                      You irrevocably submit to the exclusive jurisdiction of the courts in that State or location.
                    </p>


                    <h3>Force Majeure</h3>
                    <p>
                      MingleSea shall not be held liable for any delay or failure in performance resulting from causes beyond its reasonable control,
                      including but not limited to acts of God, natural disasters, pandemics, government actions, war, terrorism, cyberattacks, labor
                      disputes, power failures, or internet service disruptions. In such cases, MingleSea will endeavor to resume services as soon
                      as reasonably possible but shall not be held responsible for any losses incurred due to these interruptions.
                    </p>






                    <h3>Entire Agreement</h3>
                    <p>
                      This Agreement, along with our Privacy Policy and any terms disclosed to you when purchasing or accessing additional features,
                      products, or services, constitutes the entire agreement between you and MingleSea regarding your relationship with MingleSea
                      and your use of the app, website, and associated services. If any part of this Agreement is deemed invalid or unenforceable,
                      the remaining provisions will remain in full force and effect. MingleSea's failure to enforce any provision of this Agreement
                      shall not be construed as a waiver of its right to enforce that provision or any other provision in the future.You agree that
                      your MingleSea account is non-transferable, and that all rights to your account and its content terminate upon your death.
                      This Agreement does not create any agency, partnership, joint venture, fiduciary, or employment relationship between you and
                      MingleSea. You may not make statements on behalf of MingleSea or bind MingleSea in any way.
                    </p>



                  </div>

                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-seven mt-20 md-mt-0">
        <div className="lg-container">
          {/* <div className="container">
            <FooterSeven />
          </div> */}
          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default TermsConditions;
